/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from "react";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close"; // Replace with CloseIcon
import { IconButton } from "@mui/material";
import { deleteShopBanner } from "../../../API/ShopBannerApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
const ShopBanner = (props) => {
  const { name, shopId, id } = props;
  const imgUrl = `${name}?${name}:'/no-image.jpg'`;
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const erase = async () => {
    handleLoading(true);
    const data = {
      id: id,
      delImage: true,
    };
    deleteShopBanner(id, data)
      .then((res) => {
        if (res.status === true) {
          handleLoading(false);
          toast.success("Banner Deleted");
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <>
      <Card
        sx={{
          width: "300px",
          height: "auto",
          m: 3,
          p: 0,
          boxShadow: "10px 10px 5px 5px #e1e1e1",
          border: "2px #e1e1e1",
        }}>
        <IconButton
          onClick={() => erase()} // Call onDelete function with the product ID
          sx={{
            position: "absolute",

            zIndex: 1,
            color: "red", // Set the color to red
          }}>
          <CloseIcon />
        </IconButton>
        <img src={imgUrl} alt="No Image" width="300px" height="170" />
      </Card>
    </>
  );
};
export default ShopBanner;
