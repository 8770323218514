import * as React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import ShopCard from "../Cards/ShopCard";
import { getShops } from "../../../API/ShopApi";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { GridSearchIcon } from "@mui/x-data-grid";

export default function Home() {
  const [verify, setVerify] = React.useState([]);
  const [filteredVerify, setFilteredVerify] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const fetchData = async () => {
    handleLoading(true);
    try {
      const response = await getShops();
      if (response.status === true) {
        handleLoading(false);
        setVerify(response.data);
        setFilteredVerify(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    if (value.trim() === "") {
      // Reset to original data when search is cleared
      setFilteredVerify(verify);
    } else {
      // Filter the data based on search query
      const filteredData = verify.filter((shop) => {
        const name = shop.name?.toLowerCase() || "";
        const kartaName = shop.kartaName?.toLowerCase() || "";
        const mobile = shop.mobile?.toString() || ""; // Convert mobile to string safely
        return (
          name.includes(value) ||
          kartaName.includes(value) ||
          mobile.includes(value)
        );
      });
      setFilteredVerify(filteredData);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid xs={8} sx={{ direction: "rtl" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              marginTop="40px"
              marginRight="12rem">
              Store List
            </Typography>
          </Grid>
          <Grid xs={4}>
            <TextField
              sx={{ marginLeft: "10rem" }}
              label="Search"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <GridSearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "96%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          "& .MuiTableCell-root": { width: "2ch" },
          flexWrap: "wrap",
          position: "absolute",
          left: "4%",
          bgcolor: "white",
        }}>
        {filteredVerify.map((shop) => (
          <ShopCard
            key={shop._id}
            Shop_Name={shop.name}
            Karta_Name={shop.kartaName}
            Mobile={shop.mobile}
            imgUrl={shop.shopImg}
            id={shop._id}
          />
        ))}
      </Box>
    </>
  );
}
