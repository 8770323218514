/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "boxicons";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { COLORS } from "./constants";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import QuillEditor from "../Elements/RichEditor/QuillEditor";
import { getProductById, deleteProduct } from "../../API/ProductApi";
import ReactDnD from "../Elements/ReactDnD/ReactDnD";
import Variants from "./Cards/Variants";
import AddIcon from "@mui/icons-material/Add";
import AddVariants from "../Views/Modal/AddVariants";
import { backendApiUrl } from "../../constants";
import { getProductCategories } from "../../API/CategoryApi";
import { getSubCategoriesByCategoryId } from "../../API/SubCategoryApi";
import { getChildCategoriesBySubCategoryId } from "../../API/ChildCategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";

export default function Product() {
  const [img, setImg] = useState([]);
  const { shopId, id } = useParams();
  const [prevImg, setPrevImg] = useState([]);
  const [thumbnailImg, setThumbnailImg] = useState([]);
  const [variantsOpen, setVariantsOpen] = useState(false);
  const [variants, setVariants] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [childCatId, setChildCatId] = useState("");
  const [childCat, setChildCat] = useState([]);
  const [subCat, setSubCat] = useState();
  const navigate = useNavigate();
  const onImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files).map((file) => {
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces
      const cleanedFile = new File([file], fileName, { type: file.type }); // Create a new File object with the cleaned name

      return {
        id: Math.round(Math.random() * 1000000),
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      };
    });

    const selectedOrder = [];
    for (let i = 0; i < e.target.files.length; i++) {
      selectedOrder.push(e.target.files[i]);
    }

    setImg(selectedFiles);

    // Note: Logging the state immediately after setting it will not reflect the updated state
    // because state updates in React are asynchronous. The state can be checked in the useEffect hook.
  };
  const redirectToNotification = () => {
    const notificationType = "product"; // Example type
    navigate(`/admin/customNotification?id=${id}&type=${notificationType}`);
  };
  const onImageChange1 = (e) => {
    const selectedFiles = Array.from(e.target.files).map((file) => {
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces
      return new File([file], fileName, { type: file.type }); // Create a new File object with the cleaned name
    });

    setThumbnailImg(selectedFiles);
  };

  const [state, setState] = useState({
    name: "",
    desc: "",
    mrp: "",
    shopId: "",
    discount: "",
    imageURLs: [],
    videoUrl: [],
    isData: false,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const handleClickOpen = () => {
    setVariantsOpen(true);
  };

  const handleRemoveVariant = (idToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((variant) => variant.id !== idToRemove)
    );
  };
  const handleDiscount = () => {
    setDiscountPrice(
      Math.round(state.mrp - (state.mrp * state.discount) / 100)
    );
  };
  useEffect(() => {
    handleDiscount();
  }, [state.discount, state.mrp]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(thumbnailImg);
    handleLoading(true);

    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("desc", state.desc);
    formData.append("mrp", state.mrp);
    formData.append("discount", state.discount);
    formData.append("childCategoryId", childCatId._id || state.childCategoryId);
    formData.append(
      "childCategoryName",
      childCatId.name || state.childCategoryName
    );
    formData.append("subCategoryId", subCatId._id || state.subCategoryId);
    formData.append("subCategoryName", subCatId.name || state.subCategoryName);
    formData.append("categoryId", catId._id || state.categoryId);
    formData.append("categoryName", catId.name || state.categoryName);
    formData.append("shopId", shopId);
    formData.append("isVerified", true);
    formData.append("price", discountPrice);
    prevImg.forEach((url) => formData.append("imageURLs[]", url || []));

    variants.forEach((variant, index) => {
      formData.append(
        `variants[${index}][productSize]`,
        variant.productSize || ""
      );
      formData.append(
        `variants[${index}][productColor]`,
        variant.productColor || ""
      );
      formData.append(
        `variants[${index}][productPrice]`,
        variant.productPrice || ""
      );
    });
    img.forEach((image) => {
      formData.append("productImages", image.raw);
    });
    formData.append("productThumbnail", thumbnailImg[0]);

    try {
      const response = await axios.put(
        backendApiUrl + `/product/${id}`,
        formData
      );
      if (response.data.status === true) {
        handleLoading(false);

        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        handleLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = () => {
    getProductById(id)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setState(data);
        setPrevImg(data.imageURLs);

        // Assuming data.variants is an array of objects with their own IDs
        const variantsWithIds = data.variants.map((variant, index) => ({
          ...variant,
          id: index + 1, // Generate IDs based on array index (adjust as needed)
        }));

        setVariants(variantsWithIds);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchCatData = async (categortyLevel) => {
    if (categortyLevel === "category") {
      try {
        const response = await getProductCategories();
        const data = await response.data;
        setCat(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (categortyLevel === "subCategory" && catId) {
      try {
        const response = await getSubCategoriesByCategoryId(catId._id);
        const data = await response.data;
        setSubCat(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const response = await getChildCategoriesBySubCategoryId(subCatId._id);
        const data = await response.data;
        setChildCat(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchCatData("category");
    fetchData();
  }, []);
  useEffect(() => {
    if (catId) fetchCatData("subCategory");
  }, [catId]);
  useEffect(() => {
    if (subCatId) fetchCatData("childCategory");
  }, [subCatId]);

  const handleDelete = async () => {
    handleLoading(true);
    deleteProduct(id)
      .then((response) => {
        return response;
      })
      .then((data) => {
        if (data.status === true) {
          handleLoading(false);
          toast.success(data.message);
          window.history.back();
        } else {
          handleLoading(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" align="center" sx={{ marginBottom: 5 }}>
          {state.name}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 10,
          }}>
          <IconButton
            onClick={redirectToNotification}
            color="primary"
            aria-label="add notification">
            <box-icon name="bell-plus" color="currentColor"></box-icon>
          </IconButton>
        </Box>
        <Container sx={{ display: "flex", width: "100%" }} maxWidth="xl">
          <Box
            style={{
              margin: "30px",
              height: "fit-content",
              display: "grid",
              justifyItems: "center",
              alignItems: "start",
            }}>
            <Box sx={{ margin: 4 }}>
              <ReactDnD img={prevImg} setImg={setPrevImg} />
              {/* <ImageViewer /> */}
            </Box>
            {!variantsOpen && variants.length > 0 ? (
              <>
                <Box
                  style={{
                    border: "green solid 2px",
                    display: "flex",
                    justifyContent: "center",
                    width: "200px",
                    height: "50px",
                    marginBlock: "10px",
                  }}>
                  <IconButton onClick={handleClickOpen} title="Add Variants">
                    <AddIcon />
                  </IconButton>
                </Box>
                <Box textAlign="center">
                  <Typography>Variants</Typography>
                  {variants.map((v, id) => (
                    <Variants
                      key={id}
                      id={v.id} // Assign a unique identifier for each variant
                      color={v.productColor}
                      price={v.productPrice}
                      size={v.productSize}
                      onRemove={handleRemoveVariant} // Pass remove function to Variants
                    />
                  ))}
                </Box>
              </>
            ) : !variantsOpen && variants.length === 0 ? (
              <Box
                style={{
                  border: "green solid 2px",
                  display: "grid",
                  justifyContent: "center",
                  width: "200px",
                  height: "auto",
                }}>
                <Typography>Add Variants</Typography>
                <IconButton title="Add Variants" onClick={handleClickOpen}>
                  <AddIcon />
                </IconButton>
              </Box>
            ) : (
              <AddVariants
                variantsOpen={variantsOpen}
                setVariantsOpen={setVariantsOpen}
                variants={variants}
                setVariants={setVariants}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "fit-content",
              width: 1,
              borderRadius: "12px",
              "& .MuiTextField-root": { m: 1, width: "65ch" },
              paddingY: 7,
            }}>
            <Box align="center" sx={{ margin: 2 }}>
              <Box display={"flex"}>
                <Grid container justifyContent={"center"}>
                  <Grid item lg="12">
                    <TextField
                      fullWidth
                      name="name"
                      label="Name"
                      focused
                      required
                      value={state.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg="10">
                    {state.desc !== "" && (
                      <QuillEditor
                        value={state}
                        onStateChange={setState}
                        heading="Product Description *"
                        htmlKey="desc"
                      />
                    )}
                  </Grid>
                  <Grid item lg="12">
                    <TextField
                      fullWidth
                      name="mrp"
                      label="Price"
                      value={state.mrp}
                      onChange={handleChange}
                      helperText={`Discount Price is ${discountPrice}`}
                    />
                  </Grid>

                  <Grid item lg="12">
                    <TextField
                      fullWidth
                      focused
                      name="discount"
                      label="Discount"
                      value={state.discount}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg="12">
                    <Autocomplete
                      id="category"
                      options={cat}
                      getOptionLabel={(option) => option.name}
                      value={
                        cat?.find((category) => category === catId) || null
                      }
                      onChange={(event, value) => {
                        setCatId(value ? value : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Category"
                          placeholder="Select Category"
                        />
                      )}
                    />
                    <Typography fontWeight="200" fontSize="xs">
                      Current Category: {state.categoryName}
                    </Typography>
                  </Grid>

                  <Grid item lg="12">
                    <Autocomplete
                      id="subcategory"
                      options={subCat}
                      getOptionLabel={(option) => option.name}
                      value={
                        (catId &&
                          subCat?.find(
                            (subcategory) => subcategory === subCatId
                          )) ||
                        null
                      }
                      onChange={(event, value) => {
                        setSubCatId(value ? value : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Subcategory"
                          placeholder="Select Subcategory"
                        />
                      )}
                    />
                    <Typography fontWeight="200" fontSize="xs">
                      Current Sub Category: {state.subCategoryName}
                    </Typography>
                  </Grid>

                  <Grid item lg="12">
                    <Autocomplete
                      id="child-category"
                      options={childCat}
                      getOptionLabel={(option) => option.name}
                      value={
                        (subCat &&
                          childCat?.find(
                            (childCategory) => childCategory === childCatId
                          )) ||
                        null
                      }
                      onChange={(event, value) => {
                        setChildCatId(value ? value : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Child Category"
                          placeholder="Select Child Category"
                        />
                      )}
                    />
                    <Typography fontWeight="200" fontSize="xs">
                      Current Child Category: {state.childCategoryName}
                    </Typography>
                  </Grid>

                  <Grid item lg="12" m="1vh">
                    <Typography fontWeight="600">Images *</Typography>
                    <img width="200vh" src={img.preview} />
                    <Box>
                      <input
                        type="file"
                        multiple
                        accept="image/gif,image/jpg,image/jpeg,image/png"
                        onChange={onImageChange}
                      />
                      <b>{img.length}</b>
                    </Box>
                  </Grid>
                  <Grid item lg="12" m="1vh">
                    <Typography fontWeight="600">Thumbnail *</Typography>
                    <img width="200vh" src={thumbnailImg.preview} />
                    <Box>
                      <input
                        type="file"
                        multiple
                        accept="image/gif,image/jpg,image/jpeg,image/png"
                        onChange={onImageChange1}
                      />
                      <b>{thumbnailImg.length}</b>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box align="center" sx={{ "& .MuiButton-root": { m: 1 } }}>
                <Button
                  style={{ background: COLORS.defaultColor }}
                  variant="contained"
                  startIcon={<UpdateIcon />}
                  type="submit">
                  Update Product
                </Button>
                <Button
                  style={{ background: COLORS.defaultColor }}
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}>
                  <Link to={`verifiedShopsList/shopProducts/${shopId}`}></Link>
                  Delete Product
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </form>
    </>
  );
}
