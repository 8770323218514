/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import UnVerifiedProductCard from "./Cards/UnVerifiedProductCard";
import { getUnverifiedProductByShopId } from "../../API/ProductApi";
export default function UnVerifiedProduct() {
  const [products, setProducts] = useState([]);
  const shopId = useParams();
  const fetchProducts = () => {
    console.log(shopId);

    getUnverifiedProductByShopId(shopId.shopId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setProducts(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Typography variant="h3" align="center" fontWeight="600" marginTop="5vh">
        Un-Verified Products
      </Typography>
      {products.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "auto",
            margin: "50px",
            justifyContent: "center",
            "& .MuiTableCell-root": { width: "1ch" },
            flexWrap: "wrap"
          }}
        >
          {products.map((product) => (
            <UnVerifiedProductCard
              Product_Name={product.name}
              Product_Desc={product.desc}
              Price={product.price}
              imgUrl={product.imageURLs}
              id={product._id}
              shopId={shopId}
              productThumbnail={product.thumbnailURL}
            />
          ))}
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Box>
            <Typography
              align="center"
              mb="3vh"
              variant="h3"
              style={{ zIndex: "3" }}
            ></Typography>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link
                to={`/#/admin/verifiedShopsList/addProduct/${shopId}`}
                sx={{}}
              >
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
