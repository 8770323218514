import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { getShopById } from "../../../API/ShopApi";
import { createOffer } from "../../../API/OfferApi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import QuillEditor from "../../Elements/RichEditor/QuillEditor";

const AddOffers = () => {
  const { shopId } = useParams();
  const [shop, setShop] = useState({});
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [img, setImg] = useState({ preview: "", raw: null }); // Initialize raw as null
  const [termsConditions, setTermsConditions] = useState({
    termsConditions: "",
  });
  const offerStart = watch("offerStart");
  const offerEnd = watch("offerEnd");

  const fetchData = async () => {
    try {
      const response = await getShopById(shopId);
      setShop(response.data);
      setValue("shopName", response.data.name);
    } catch (error) {
      console.error("Error fetching shop data:", error);
    }
  };

  const onImageChange = (e) => {
    setImg({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const handleDateChange = (date, field) => {
    if (field === "offerStart" && date) {
      const newEndDate = new Date(date.getTime() + 24 * 60 * 60 * 1000); // Set default offerEnd to one day later
      setValue("offerEnd", newEndDate.getTime());
    }
    setValue(field, date ? date.getTime() : null);
  };

  const onSubmit = async (data) => {
    handleLoading(true);

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("discount", data.discount);
    formData.append("shopId", shopId);
    formData.append("termsConditions", termsConditions.termsConditions);
    formData.append("offerStart", data.offerStart);
    formData.append("offerEnd", data.offerEnd);
    formData.append("shopName", shop.name); // Use the fetched shop name
    formData.append("img", img.raw);
    formData.append("isVerified", true);

    try {
      const response = await createOffer(formData);
      handleLoading(false);

      if (response.status === true) {
        toast.success(`${response.message}`);
        handleLoading(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000); // Delay reload by 2 seconds
      } else {
        handleLoading(false);
        toast.error(`${response.message}`);
      }
    } catch (error) {
      handleLoading(false);
      toast.error("Error submitting form: " + error.message);
    }
  };

  useEffect(() => {
    console.log(img);
  }, [img]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "60px" }}>
        Create an Offer
      </h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="name"
            label="Offer Name"
            onChange={(e) => setValue("name", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="discount"
            type="text"
            label="Discount"
            {...register("discount", { required: "Discount is required" })}
            error={!!errors.discount}
            helperText={errors.discount?.message}
            onChange={(e) => setValue("discount", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="shopId"
            label="Shop ID"
            value={shopId}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="shopName"
            label="Shop Name"
            value={shop.name}
            focused
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={offerStart ? new Date(offerStart) : null}
            onChange={(date) => handleDateChange(date, "offerStart")}
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()} // Prevent selection of past dates
            customInput={<TextField fullWidth label="Offer Start" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={offerEnd ? new Date(offerEnd) : null}
            onChange={(date) => handleDateChange(date, "offerEnd")}
            showTimeSelect
            dateFormat="Pp"
            minDate={
              offerStart
                ? new Date(offerStart + 24 * 60 * 60 * 1000)
                : new Date()
            }
            customInput={<TextField fullWidth label="Offer End" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <QuillEditor
            value={termsConditions}
            onStateChange={setTermsConditions}
            heading="About the Offer"
            title="About the Offer"
            htmlKey="termsConditions"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>Add Image</div>
          <input type="file" accept="image/*" onChange={onImageChange} />
          {img.preview && (
            <img src={img.preview} width="200px" height="200px" alt="Preview" />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Create Offer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const formStyle = {
  margin: "20px auto",
  maxWidth: "700px",
};

export default AddOffers;
