import { Box } from "@mui/system";
import * as React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./Components/Elements/SideBar/SideBar.js";

function RoutesFunc() {
  return (
    <>
      <Box>
        <SideBar />

        <Outlet />
      </Box>
    </>
  );
}

export default RoutesFunc;
