/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import ShopBannerCard from "./Cards/ShopBannerCard";
import { getShopBannerByShopId } from "../../API/ShopBannerApi";
export default function BannerList() {
  const [banner, setBanner] = useState([]);
  const { shopId } = useParams();

  const fetchBanner = () => {
    getShopBannerByShopId(shopId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setBanner(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <>
      {banner.length > 0 ? (
        <Box>
          <Box>
            <Typography
              variant="h3"
              align="center"
              fontWeight="600"
              marginTop="5vh"
            >
              Shop Banner
            </Typography>
          </Box>
          <Box align="right" margin="20px">
            <Link to={`/admin/verifiedShopsList/addShopBanner/${shopId}`}>
              <Button variant="contained">Add Banner</Button>
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "96%",
              marginLeft: "60px",
              justifyContent: "center",
              "& .MuiTableCell-root": { width: "1ch" },
              flexWrap: "wrap"
            }}
          >
            {banner.map((product) => (
              <ShopBannerCard
                key={product._id}
                id={product._id}
                name={product.shopBanner}
                shopId={shopId}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Box>
            <Typography
              align="center"
              marginBottom="170px"
              fontWeight="600"
              variant="h3"
              style={{ zIndex: "3" }}
            >
              Shop Banners
            </Typography>

            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link
                to={`/admin/verifiedShopsList/addShopBanner/${shopId}`}
                sx={{ textDecoration: "none" }}
              >
                <Button variant="contained" style={{ color: "white" }}>
                  Add Some
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
