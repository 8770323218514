// ShopApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const ShopApi = axios.create({
  baseURL,
});

// // Add an interceptor to include the token in the Authorization header for all requests
// ShopApi.interceptors.request.use(
//   (config) => {
//     const authToken = store.getState().auth.authToken; // Assuming you store the token in localStorage
//     console.log(authToken);
//     if (authToken) {
//       config.headers.Authorization = `${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Define your shop-related API endpoints
export const getShops = async (isBlocked = false, page = 1, count = 50) => {
  try {
    const response = await ShopApi.get(
      `/shops?isBlocked=${isBlocked}&page=${page}&count=${count}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getShopById = async (id) => {
  try {
    const response = await ShopApi.get(`/shops/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateShop = async (id, shopData) => {
  try {
    const response = await ShopApi.put(`/shops/${id}`, shopData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createShopApi = async (shopData) => {
  try {
    const response = await ShopApi.post("/shops/", shopData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteShop = async (id, userId) => {
  try {
    const response = await ShopApi.delete(`/shops/${id}`, {
      data: { userId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateFollowingShop = async (id, followingData) => {
  try {
    const response = await ShopApi.put(`/shops/${id}/following`, followingData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFollowingShop = async (id) => {
  try {
    const response = await ShopApi.delete(`/shops/${id}/following`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other shop-related API endpoints as needed

export default ShopApi;
