import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { backendApiUrl } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";

export default function ShopBanner() {
  const { shopId } = useParams();
  const [img, setImg] = useState({ preview: "", raw: "" });

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setImg({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });
    }
  };

  useEffect(() => {
    console.log(img);
  }, [img]);

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("shopBannerImg", img.raw);
    formData.append("shopId", shopId);
    createBanner(formData);
    //   try {
    //     const response = await axios.post(
    //       "http://api.litchies.com/uploadImage",
    //       formData
    //     );
    //     const id = response.data.name;
    //     setState({
    //       shopBanner: id,
    //       shopIds: [shopId]
    //     });
    //     if (response.status === 200 && state.shopBanner !== "") {
    //       toast("Banner Uploaded");
    //       createBanner();
    //     }
    //   } catch (error) {
    //     console.error("Error uploading image:", error);
    //   }
  };
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const createBanner = async (formData) => {
    handleLoading(true);
    try {
      await axios.post(backendApiUrl + "/shopBanner", formData).then((res) => {
        console.log(res);
        if (res.data.status === true) {
          handleLoading(false);
          toast.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.error("Error creating banner:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleUpload}>
        <Container>
          <Typography mt="4" align="center" variant="h3" fontWeight="700">
            Add Shop Banner
          </Typography>

          <Box align="center" sx={{ marginY: 40 }}>
            <Box style={{ marginTop: "50px" }}>
              <img
                style={{ width: "250px", marginLeft: "30px" }}
                src={img.preview}
                alt="Preview"
                accept="image/*"
              />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <input width="100%" type="file" onChange={onImageChange} />
              <Button
                variant="contained"
                disabled={!img.raw}
                onClick={handleUpload}>
                Upload
              </Button>
            </Box>
          </Box>
        </Container>
      </form>
    </>
  );
}
