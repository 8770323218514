/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import { Container } from "@mui/system";
import { getProducts } from "../../API/ProductApi";
import ProductCard from "./Cards/ProductCard";
export default function NewRequestList() {
  const [products, setProducts] = useState([]);

  const fetchData = () => {
    getProducts()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setProducts(data.filter((d) => d.isVerified === false));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Typography
        position="absolute"
        top="40px"
        left="40%"
        variant="h3"
        style={{ zIndex: "3" }}
      >
        New Requests
      </Typography>
      {products.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "auto",
            height: "250px",
            paddingY: "50px",
            marginX: "8%",
            marginY: "6%",
            justifyContent: "center",
            "& .MuiTableCell-root": { width: "1ch" }
          }}
        >
          <Container maxWidth={"xl"}>
            <Grid container spacing={4}>
              {products.map((verifieduser) => (
                <Grid item md={4} lg={4} sm={12}>
                  <ProductCard
                    key={verifieduser._id}
                    Product_Name={verifieduser.name}
                    Product_Desc={verifieduser.desc}
                    Price={verifieduser.price}
                    imgUrl={verifieduser.imageURLs}
                    id={verifieduser._id}
                    // shopId={shopId}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Box>
            <Typography
              position="absolute"
              top="40px"
              left="40%"
              variant="h3"
              style={{ zIndex: "3" }}
            >
              New Requests
            </Typography>

            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
