/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import ProductCard from "./Cards/ProductCard";
import { getProductByShopId } from "../../API/ProductApi";
export default function ProductList() {
  const [products, setProducts] = useState([]);
  const { shopId, id } = useParams();

  const fetchProducts = () => {
    getProductByShopId(shopId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data);
        setProducts(data.filter((d) => d.isVerified === true));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh"
        >
          Shop Products
        </Typography>
      </Box>
      {products.length > 0 ? (
        <Box>
          <Box align="right" margin="20px">
            <Link to={`/admin/verifiedShopsList/addProduct/${shopId}`}>
              <Button variant="contained">Add Product</Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "96%",
              marginLeft: "60px",
              justifyContent: "center",
              "& .MuiTableCell-root": { width: "1ch" },
              flexWrap: "wrap"
            }}
          >
            {products.map((product) => (
              <ProductCard
                key={product._id}
                Product_Name={product.name}
                Product_Desc={product.desc}
                Price={product.price}
                imgUrl={product.thumbnailURL}
                id={product._id}
                mrp={product.mrp}
                shopId={shopId}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Box>
            <Typography
              align="center"
              mb="3vh"
              variant="h3"
              style={{ zIndex: "3" }}
            >
              Verified Product List
            </Typography>

            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link to={`/admin/verifiedShopsList/addProduct/${shopId}`}>
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
