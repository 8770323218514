import React from "react";
import OfferCard from "./Cards/OfferCard/OfferCard";
import { Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import "./OfferList.css";

const OfferList = (props) => {
  const { offersData } = props;
  const { shopId } = useParams();

  return (
    <>
      <h3 className="heading">Shop Offers</h3>
      <Box sx={{ display: "flex", justifyContent: "right", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "0 10px" }}
          href={`#/admin/verifiedShopsList/addOffer/${shopId}`}>
          Add Offers
        </Button>
      </Box>
      {offersData.length > 0 ? (
        <Grid
          container
          spacing={3}
          style={{ marginInline: "60px", marginBottom: "20px" }}>
          {offersData.map((offer, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.8} xl={2.3} key={index}>
              <OfferCard
                imageUrl={offer.imgURL}
                offerText={offer.offerCode}
                offerId={offer._id}
                linkUrl={`/#/admin/verifiedShopsList/editOffer`}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh", // Adjust to take up some vertical space
          }}>
          <Typography variant="h6" align="center">
            No offers available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default OfferList;
